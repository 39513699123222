<template>
	<div class="list">
		<div class="header">
			<img src="@/assets/img/xintp/wb_juan_zeng_header.jpg" />
		</div>
		<form class="form-donate" method="post" action="" enctype="multipart/form-data" @submit.prevent="formSubmit">
			<div class="form-donate-item">
				<select class="form-donate-select" name="type_id" @change="changeType">
					<option value="">请选择提供藏品方式</option>
					<option :value="item.id" v-for="(item, index) in pickerDataType" :key="index">{{ item.title }}</option>
				</select>
			</div>
			<div class="form-donate-item" v-if="dataForm.type == 2">
				<input type="number" step="0.01" name="money" placeholder="请输入您的意向价格">
			</div>
			<div class="form-donate-item">
				<select class="form-donate-select" name="collection_period_id">
					<option value="">请选择藏品时期</option>
					<option :value="item.id" v-for="(item, index) in pickerDataCollectionPeriod" :key="index">{{ item.title
					}}</option>
				</select>
			</div>
			<div class="form-donate-item">
				<select class="form-donate-select" name="category_id">
					<option value="">请选择文物分类</option>
					<option :value="item.id" v-for="(item, index) in pickerDataCategory" :key="index">{{ item.title }}
					</option>
				</select>
			</div>
			<div class="form-donate-item">
				<textarea name="body" cols="30" rows="3" placeholder="藏品描述：（200字以内）"></textarea>
			</div>
			<div class="form-donate-item">
				<textarea name="source" cols="30" rows="3" placeholder="藏品真实来源：（流传至您手中的经历；200字以内）"></textarea>
			</div>
			<div class="form-donate-item">
				<div class="litpics-show">
					<div class="item" v-for="(item, index) in dataForm.litpics" :key="index"><img :src="item" alt="">
					</div>
				</div>
				<file-upload multiple id="litpics" ref="uploadLitpics" :post-action="apiUrl" :accept="'image/*'"
					:size="dataForm.maxSize" @input-file="inputFile" @input-filter="inputFilter">
					<button type="button" class="upload-btn">上传文件</button>
				</file-upload>
			</div>
			<div class="form-donate-item">
				<input type="text" name="truename" placeholder="请输入您的姓名">
			</div>
			<div class="form-donate-item">
				<input type="text" name="tel" placeholder="请输入您的联系方式">
			</div>
			<div class="form-donate-item">
				<input type="text" name="address" placeholder="请输入您的详细地址">
			</div>
			<div class="form-donate-btns">
				<button class="btn reset" type="reset">重置</button>
				<button class="btn" type="submit">提交</button>
			</div>
		</form>
		<router-view @getLoad="showMsg" />
	</div>
</template>

<script>
import UploadComponent from 'vue-upload-component';
import api from '../../api';
import jquery from 'jquery';
export default {
	components: {
		'file-upload': UploadComponent
	},
	data() {
		return {
			apiUrl: api.getUrl('/uploads/index'),
			dataForm: {
				maxSize: 1024 * 1024 * 10,
				type: 0,
				litpics: [],
			},
			pickerDataType: [
				{ id: 1, title: '捐赠' },
				{ id: 2, title: '出售' },
				{ id: 3, title: '移交' },
			],
			pickerDataCollectionPeriod: [
				{ id: 1, title: '1840年之前' },
				{ id: 2, title: '1840年~1949年' },
				{ id: 3, title: '新中国成立以来' },
				{ id: 4, title: '新时代（十八大以来）' },
			],
			pickerDataCategory: [
				{ id: 1, title: '古代文物' },
				{ id: 2, title: '近现代文物' },
				{ id: 3, title: '域外文物' },
				{ id: 4, title: '非正式出版物' },
				{ id: 5, title: '艺术品' },
				{ id: 99, title: '其他' },
			],
		}
	},

	methods: {
		changeType(e) {
			// console.log(e, e.target.value)
			this.dataForm.type = e.target.value;
		},
		inputFilter(file) {
			// console.log(file);
			this.$refs.uploadLitpics.active = true;
		},
		inputFile(newFile, oldFile) {
			if (newFile && oldFile && !newFile.active && oldFile.active) {
				// 获得相应数据
				// console.log('response', newFile.response)
				if (newFile.xhr) {
					//  获得响应状态码
					console.log('status', newFile.xhr.status)
					if (newFile.xhr.status == 200) {
						console.log('response', newFile.response);
						let { code, msg, data } = newFile.response;
						if (code) {
							if (!this.dataForm.litpics.includes(data.path)) {
								this.dataForm.litpics.push(data.path);
							}

						} else {
							alert(msg);
							this.$refs.uploadLitpics.active = false;
						}
					} else {
						alert('上传错误，请联系客服');
						this.$refs.uploadLitpics.active = false;
					}
				}
			}
		},
		showMsg() {
			var that = this
			that.$emit('getLoad', true);
		},
		formSubmit(e) {
			console.log(e.target, jquery);
			let formData = jquery(e.target).serializeArray();
			for (let x in this.dataForm.litpics) {
				formData.push({ name: 'litpics[]', value: this.dataForm.litpics[x] });
			}

			jquery.ajax({
				url: api.getUrl('/Donate/save'),
				data: formData,
				type: 'POST',
				success: res => {
					alert(res.msg);
					if (res.code) {

					} else {

					}
				}
			})
			console.log(formData);
		},
	}
}
</script>

<style lang="less" scoped>
.form-donate {
	margin: 20px auto;

	&-item {
		margin-bottom: 15px;
	}

	select,
	input,
	textarea {
		border: 1px solid #ddd;
		border-radius: 4px;
		width: 100%;
		box-sizing: border-box;

		color: #555;
		line-height: 30px;
	}

	select,
	input {
		@height: 30px;

		line-height: @height;
	}

	select {
		padding: 5px 3px;
	}

	textarea,
	input {
		padding: 0px 8px;

	}

	.upload-btn {
		background: #26b6b4;
		border: 1px solid #ddd;
		padding: 6px 30px;
		border-radius: 4px;
		color: #fff;
	}

	&-btns {
		margin-bottom: 30px;

		text-align: center;

		.btn {
			background: #26b6b4;
			border: 1px solid #ddd;
			padding: 6px 30px;
			border-radius: 4px;

			color: #fff;
		}

		.reset {
			background: #fff;
			color: #333;
		}

		.btn+.btn {
			margin-left: 20px;
		}
	}

	.litpics-show {
		display: flex;
		flex-wrap: wrap;

		.item {
			@width: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: @width;
			height: @width;
			overflow: hidden;
			border: 1px solid #ddd;
			padding: 2px;
			margin-bottom: 10px;

			img {
				max-width: 100%;
				max-height: 100%;
			}

			&+.item {
				margin-left: 10px;
			}
		}
	}


	@media (min-width:900px) {
		max-width: 83.07%;
	}

}
</style>
